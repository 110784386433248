import Popper from 'popper.js';
// import React from "react";
// import ReactDOM from "react-dom";

window.$ = window.jQuery = require('jquery');
window.Popper = Popper;
window.Cookies = require('js-cookie');
window.moment = require('moment');
// window.React = React;
// window.ReactDOM = ReactDOM;

window.ParsleyConfig = {
    successClass: '',
    errorClass: 'is-invalid',
    // classHandler: function (field) {
    //     return field.$element.closest('.form-group, [class^=col-], .textarea-wrapper');
    // },
    errorsContainer: function (field) {
        return field.$element.closest('.form-group, [class^=col-], .textarea-wrapper');
    },
    errorsWrapper: '<span class="invalid-feedback"></span>',
    errorTemplate: '<div></div>',
    trigger: 'change',
};

require('bootstrap/dist/js/bootstrap.js');
require('select2/dist/js/select2.min');
require('tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.min');
require('slick-carousel/slick/slick.min.js');
require('parsleyjs/dist/parsley.min');

window.Parsley.on('form:validate', function() {
    var $form = this.$element;

    $form.find('[data-formset-body] [data-formset-form]').each(function(){
        var is_empty = true;
        var $fields = $(this).find(':input:not([type=hidden],[name$="-DELETE"],button)');

        if($(this).data('formset-form-deleted') == ''){
            $fields.removeAttr('data-parsley-required').removeAttr('required').attr('data-parsley-excluded', 'true');
            return true;
        }

        $fields.filter(':not([data-ignore-empty])').each(function(){
            if($(this).val()){
                is_empty = false;
                return false;
            }
        });

        if(is_empty) {
            $fields.filter('[data-parsley-required]').attr('data-was-required', 'true').removeAttr('data-parsley-required').removeAttr('required');
        }else{
            $fields.filter('[data-was-required=true]').removeAttr('data-was-required').attr('required', 'true').attr('data-parsley-required', 'true');
        }
    });
});
