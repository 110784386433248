
// let hot;
// if (process.env.NODE_ENV === 'develop') {
//     require("react-hot-loader/patch");
//     hot = require("react-hot-loader").hot;
// }

import { styles } from '../css/style.scss';

require('./libs');

// If using react, follow this example to have components hot reloaded in dev environments
// function register(name, component) {
//     if (process.env.NODE_ENV === 'develop') {
//         window[name] = hot(module)(component);
//     } else {
//         window[name] = component;
//     }
// }
//
// Assuming you're loading a component that lives in a file in a subfolder of frontend/js,
// replace "<<  >>" below with appropriate values
//
// import { <<ModuleName>> } from './<<sub_dir>>/<<file_name>>';
//
// register('<<ModuleName>>', <<ModuleName>>);
